<template>
  <section id="partnership-form-container" >
    <div class="container">
      <p class="
              h2
              text-main text-uppercase
              fw-bold
              px-2
              text-center
              partnership-form-heading
            ">
        <span class="text-blue">ƏMƏKDAŞLIQ</span> ÜÇÜN MÜRACİƏT FORMASI
      </p>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mx-auto py-5">
        <div class="
            partnership-form
            col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
            py-1
            px-0
            position-relative
          ">
          <div class="col-3 form-paper">
            <img src="@/assets/images/paper.png" alt="paper" class="form-paper-img">
          </div>
          <div class=" partnership-form-contact">
            <form @submit.prevent="submitHandler">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="form-group position-relative mt-4" :class="{ error: v$.form.fullName.$errors.length }">
                    <input type="text" class="form-control partner-form-control" v-model="form.fullName"
                      placeholder="Adınız" />
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="form-group position-relative mt-4" :class="{ error: v$.form.phoneNumber.$errors.length }">
                    <input type="text" asp-for="PhoneNumber" class="form-control partner-form-control "
                      v-maska="'+994## ###-##-##'" v-model="form.phoneNumber" placeholder="Telefon nömrəsi" />
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="form-group position-relative mt-4" :class="{ error: v$.form.email.$errors.length }">
                    <input type="text" asp-for="Email" class="form-control partner-form-control" v-model="form.email"
                      placeholder="Elektron poçt ünvanı" />

                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="form-group position-relative mt-4" :class="{ error: v$.form.company.$errors.length }">
                    <input type="text" asp-for="Company" class="form-control partner-form-control" v-model="form.company"
                      placeholder="Şirkət adı" />

                  </div>
                </div>
              </div>

              <div class="form-group mt-4" :class="{ error: v$.form.description.$errors.length }">
                <textarea v-model="form.description" class="form-control partner-form-control" rows="5"
                  placeholder="Mesajınız"/>
                </div>

              <div class="form-group text-start mt-4">
                <button type="submit" class="btn-main btn-default btn-hover w-100 contact-submit" :disabled="isLoading">
                  <span class="spinner-border spinner-border-sm" v-if="isLoading" />
                  Göndər
                </button>
              </div>
            </form>
          </div>
          <div class="col-4 form-letter">
            <img src="@/assets/images/letter.svg" alt="letter" class="form-letter-img">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { maska } from "maska";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import api from "@/api/contact.api";

const initialValue = {
  fullName: null,
  phoneNumber: null,
  email: null,
  description: null,
  company: null,
  source:null
};

const validations = {
  fullName: { required },
  phoneNumber: { required },
  email: { required },
  description: { required },
  company: { required },
};

export default {
  directives: { maska },
  data: () => ({
    isLoading: false,
    form: { ...initialValue },
  }),
  validations: () => ({
    form: { ...validations },
  }),
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    async submitHandler() {
      this.form.source="Korporativ"
      const result = await this.v$.$validate();
      if (result) {
        this.isLoading = true;
        api
          .sendMessage(this.form)
          .then(() => {
            this.isLoading = false;
            this.form = initialValue;
            this.emitter.emit("info", "Məlumat uğurla göndərildi!");

            this.v$.$reset();
          })
          .catch((error) => this.emitter.emit("error", error))
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>